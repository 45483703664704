let baseClass = require('@/client/extensions/composition/asyncOperations/requestAdapters/interface.class.js');
let conf = config.asyncData.requestAdapters.saffronAxios;
let _ = require('lodash/object');


class SaffronAxiosAdapter extends baseClass.default {
    conf = config.asyncData.requestAdapters.saffronAxios;

    axios = null;
    rootUrl = conf.rootUrl;
    options = {
        rootUrl : conf.rootUrl,
    };

    constructor (options) {
        super(options);
        this.axios = require('axios').default;
        return this;
    }

    getRequestOptions (options) {
        return _.merge({}, this.options, options);
    }

    enforceGetAxiosOptionsIntegrity (options) {
        if (typeof options !== 'object') {
            options = {};
        }

        if ( ! options.headers) {
            options.headers = {};
        }

        // required for CORS
        if ( ! options.withCredentials) {
            options.withCredentials = true;
        }

        if (options.authorization) {
            let token     = options.authorization.token     || '';
            let tokenType = options.authorization.tokenType || '';

            if (token && token !== '') {
                options.headers['Authorization'] = `${tokenType} ${token}`;
            }

        }
    }

    getAxiosPromise (url, data = {}, options = {}, method = 'get') {
        this.enforceGetAxiosOptionsIntegrity(options);

        // allow same api for all methods. get has unique API in axios.
        if (method === 'get') {
            if (typeof options.params == 'undefined')  options.params = data;
            return this.axios.get(url, options)
        }

        // allow same api for all methods. delete has unique API in axios.
        if (method === 'delete') {
            options.params = data || {};
            return this.axios.delete(url, options)
        }

        return this.axios[method](url, data, options);
    }

    async _fetch (url, data, options, method = 'post') {
        let requestOptions = this.getRequestOptions(options);

        // auto prefix to url, unless requested specifically
        if ( ! options.requestUrlFull) {
            url = requestOptions.rootUrl + url;
        }


        try {

            return await this.getAxiosPromise(url, data, requestOptions, method).then((result) => {
                if ((options.requestReturnType || 'false') === 'all') {
                    return result;
                } else {
                    return result.data;
                }
            });
        } catch (err) {
            return this.parseError(err);
        }
    }

    async get (url, data = null, options = {}) {
        return this._fetch(url, data, options, 'get');
    }

    async post (url, data, options = {}) {
        return this._fetch(url, data, options, 'post');
    }

    async put (url, data, options) {
        return this._fetch(url, data, options, 'put');
    }

    async patch (url, data, options) {
        return this._fetch(url, data, options, 'patch');
    }

    async delete (url, data, options) {
        return this._fetch(url, data, options, 'delete');
    }

    parseError (err) {
        let res = err.response;

        let final = {
            'status' : {
                'code' : res.status,
                'message': res.statusText
            },
            data : res.data
        };

        return final
    }
}

export default (options) => {
    return new SaffronAxiosAdapter(options)
};
